import Header from "./components/Header"
import Images from "./components/Images"
import About from "./components/About"
import Exhibition from "./components/Exhibition"
import Footer from "./components/Footer"
import data from "./data"


function App() {
  const aboutElements = data.textData.map(data => {
    return <About title={data.title} text1={data.text1} text2={data.text2} key={data.title} />
  })

  const exhibitionElements = data.exhibitionData.map((data, id) => {
    return <Exhibition year={data.year} text={data.text} key={id}/>
  })

  return (
    <div className="container">
      <Header />
      <div className="images--container">
        <Images img="./images/1.jpg"/>
        <Images img="./images/2.jpg"/>
        <Images img="./images/3.jpg"/>
      </div>
      <h3 className="category-title">Text</h3>
      <div className="about--container">{aboutElements}</div>
      <div className="images--container">
        <span className="square-image"><Images img="./images/4.jpg"/></span>
        <span className="square-image"><Images img="./images/5.jpg"/></span>
        <Images img="./images/6.jpg"/>
      </div>
      <h3 className="category-title">exhibitions</h3>
      <div className="exhibition--container">{exhibitionElements}</div>
      <div className="images--container">
      <span className="landscape-image"><Images img="./images/7.jpg"/></span>
        <Images img="./images/8.jpg"/>
        <Images img="./images/9.jpg"/>
      </div>
      <Footer />
    </div>
  );
}

export default App;
