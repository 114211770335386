const About = (props) => {
    return (
        <div>
            <h3 className="about-title">{props.title}</h3>
            <div className="about-columns">
                <div className="about-text1"><p>{props.text1}</p></div>
                <div className="about-text1"><p>{props.text2}</p></div>
                </div>
            
        </div>
    ) 
}

export default About