const Exhibition = (props) => {
    return (
        <div>
            <h3 className="exhibition-title">{props.year}</h3>
            <p className="exhibition-text">{props.text}</p>
            <hr ></hr>
        </div>
    )
}

export default Exhibition