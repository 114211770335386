export default {

    textData: [
        {
            title: "- Anna Lopez Pujal 2017",
            text1: `Heidi Wallaeys' canvasses show us images of an ephemeral
nature: the capricious flight of birds, the soft falling of snow, 
hesitant still waters, mountains frozen in the absence of wind, 
the scintillating light of a summer sun or the shadow of a tree 
disappearing in the night.
       
The vast and ethereal outdoor landscapes of freedom and infinity 
evoke an inner space, one without the limits imposed by our existence.  
In that space, the promise of the intimate and veiled but boundless landscape 
of our thoughts, our feelings and our imagination, unfolds.  
The artist calls this in one of her paintings 'the sky in my mind' 
quoting Virginia Woolf. 
Likewise, this tangible spaciousness suggests the unbridgeable distance 
that separates us, it depicts the difficulty to find each other. 
Colour, very present in Heidi Wallaeys' work, is never neutral. `, 

text2:`Limpid turquoise, wavering purple-blue, vibrant yellow, some 
sumptuous pinks as well as white and black appear on monochromatic surfaces, 
always applied in subtle shades and transparencies.  
With some brushstrokes, lines or stains and simple means of expression, 
the painter achieves to create scenes which bring to mind the concision 
of the Japanese haiku. 
This association is not arbitrary, since one of her references is the beautiful
'the book of tea' by Okakura Kakuzo,
an aesthetical guide and a philosophy for life. 
Heidi Wallaeys' whole artwork originates from a real need and from a definite 
vision on the world. 
As well as her paintings, her drawings and her prints – which seem miniatures 
out of antiquarian books – claim something we nowadays often lack : delicacy.`
}
    ],

    exhibitionData: [
        {
            year: "2023",
            text: "Hertenbos kapel / Hertsberge / B"
        },
        {
            year: "2022",
            text: "Orangerie du chateau / montéléger / F"
        },
        {
            year: "2021",
            text: "Imprints-Galerie / Crest / F"
        },
        {
            year: "2021",
            text: "Bib Oostkamp / B"
        },
        {
            year: "2017",
            text: "Platform voor actuele kunst PAK Gistel"
        },
        {
            year: "2016",
            text: "Galerie Hôtel de Clérieu, Romans sur Isère F"
        },
        {
            year: "2014",
            text: "A loft / gent / B / chapelle des gillons / chatillon st jean / F"
        },
        {
            year: "2010",
            text: "orangerie du chateau / Montéléger / F / galerie ‘handle with care’ / Crest / F"
        },
        {
            year: "2008",
            text: "brittania hotel / Knokke / B"
        },
        {
            year: "2007",
            text: "galerie remise / BN / D"
        },
        {
            year: "2006",
            text: "kunstcentrum di coylde / Beernem / B"
        },
        {
            year: "2005",
            text: "de wieke / oostkamp/ B"
        },{
            year: "2003",
            text: "n°7 beukendreef / hertsberge / B"
        },{
            year: "2002",
            text: "galerie L. Rekow / Crest / F"
        },{
            year: "2001",
            text: "kunstcentrum di coylde / Beernem / B"
        },{
            year: "2000",
            text: "galerie de la mapra / Lyon / F"
        },{
            year: "1999",
            text: "centrum voor jonge kunst / Gent / B / galerie L Hinnekens / Kortrijk / B"
        },{
            year: "1998",
            text: "expo de groupe / Ivrea / I / la maison de la danse / Lyon / F / art forum / Montreux / CH"
        },
        {
            year: "1997",
            text: "festival de la danse / portes les valence / F / théâtre des cordeliers (musée) / Romans / F"
        },{
            year: "1996",
            text: "danshuis tamara jackson / Brugge / B"
        },{
            year: "1995",
            text: "chapelle des Jèsuites / Chaumont / F / palais Dephinal / St Donat / F"
        },{
            year: "1994",
            text: "Imewo / Knokke / F /galerie minotaurus / Brugge / B"
        },
        {
            year: "1993",
            text: "kunstcentrum st janshospitaal / Brugge / B / bbl / Roeselare / B"
        },{
            year: "1992",
            text: "galerie tempo / Roeselare / B / sedia / oostkamp / B / beukenpark / oostkamp / B"
        },{
            year: "1991",
            text: "kulturamt altes rathaus / BN / D"
        },
        {
            year: "1990",
            text: "galerie amphora / brugge / B"
        },
    ]
        
}

    

