const Footer = () => {
    return (
        <div className="footer">
            <a className="footer-contact" href="mailto:heidiwallaeys@hotmail.com">heidiwallaeys@hotmail.com</a>
            <div className="footer-copyright">&copy; Heidi Wallaeys</div>
        </div>
        
    )

}

export default Footer